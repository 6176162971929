import "./projects.css";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { ProjectCard } from "../";
import backgroundImageRight from "../../assets/img/color-sharp2.png";
import netflixCloneImage from "../../assets/img/netflix-clone-project.png";
import projectImage2 from "../../assets/img/project-img2.png";
import projectImage3 from "../../assets/img/project-img3.png";
import portfolioImage from "../../assets/img/portfolio-project.png";
import userMsProjectImage from "../../assets/img/user-ms-project.png";

const backendProjects = [
  {
    title: "NestJS Users MS",
    description:
      "Basic CRUD for user management using NestJS with Typescript & PostgreSQL hosted in Docker. Implementing CQRS, swagger and full testing of the files.",
    imgUrl: userMsProjectImage,
    hasLiveDemo: false,
    githubUrl:
      "https://github.com/MatiasDelorenzi/nestjs-postgresql-cqrs-users-crud",
  },
  {
    title: "React project",
    description: "React project",
    imgUrl: projectImage2,
    hasLiveDemo: true,
  },
  {
    title: "React project",
    description: "React project",
    imgUrl: projectImage3,
    hasLiveDemo: true,
  },
];

const frontEndProjects = [
  {
    title: "Netflix Clone",
    description:
      "Using ReactJS and fetching the data from The Movie Database. Hosted in Firebase.",
    imgUrl: netflixCloneImage,
    hasLiveDemo: true,
    githubUrl:
      "https://github.com/MatiasDelorenzi/netflix-clone-react#:~:text=This%20project%20was%20created%20only%20for%20learning%20purposes.%20All%20the%20information%20realated%20to%20logos%2C%20series%20and%20TV%20shows%20belongs%20to%20it%C2%B4s%20creators.",
    liveUrl: "https://netflix-clone-fb9cf.web.app/",
  },
  {
    title: "My Portfolio",
    description: "Portfolio created with ReactJS.",
    imgUrl: portfolioImage,
    hasLiveDemo: true,
    githubUrl: "https://github.com/MatiasDelorenzi/reactjs-portfolio",
    liveUrl: "http://mdelorenzi.com",
  },
  {
    title: "React project",
    description: "React project",
    imgUrl: projectImage3,
    hasLiveDemo: true,
  },
];

const Projects = () => {
  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <h2>Projects</h2>
            <p>Here are some of the projects I've worked on</p>
            <Tab.Container id="project-tabs" defaultActiveKey="first">
              <Nav
                variant="pills"
                className="nav-pills mb-5 justify-content-center align-items-center"
                id="pills-tab"
              >
                <Nav.Item>
                  <Nav.Link eventKey="first">Backend</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Frontend</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="third">UI/UX</Nav.Link>
                </Nav.Item> */}
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row>
                    <Container className="card-container">
                      {backendProjects.map((project, index) => {
                        return (
                          <ProjectCard
                            className="project-card"
                            key={index}
                            {...project}
                          />
                        );
                      })}
                    </Container>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Row>
                    <Container className="card-container">
                      {frontEndProjects.map((project, index) => {
                        return (
                          <ProjectCard
                            className="project-card"
                            key={index}
                            {...project}
                          />
                        );
                      })}
                    </Container>
                  </Row>
                </Tab.Pane>
                {/* <Tab.Pane eventKey="third">Lorem ipsum</Tab.Pane> */}
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <img
        className="background-image-right"
        alt="Project"
        src={backgroundImageRight}
      />
    </section>
  );
};

export default Projects;
