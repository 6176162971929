import "./about.css";
import { Col, Row, Container } from "react-bootstrap";
import backgroundImageLeft from "../../assets/img/color-sharp.png";
import headerImg from "../../assets/img/header-img.svg";

const About = () => {
  return (
    <section className="about" id="about">
      <Container>
        <Row>
          <Col xs={12} md={6} xl={5}>
            <img src={headerImg} alt="Headder Img"></img>
          </Col>
          <Col xs={12} md={6} xl={7}>
            <h1>About Me.</h1>
            <p>
              My name is Matias and I am a Software Engineer specialized in
              NodeJS based in Argentina.
            </p>
            <p>
              My interests in web development started back in 2016 when I
              decided to try creating an app that help me organize mi daily
              tasks. Turns out this little idea taught me a lot about NodeJS!
            </p>
            <p>
              Fast-forward to today, I use my passion for technology, skills and
              knowledge to help people and companies through software
              development.
            </p>
            <p>Here are a few techologies I've been working with recently:</p>
            <div className="technologies-container">
              <ul>
                <li>NodeJS</li>
                <li>ReactJS</li>
                <li>Javascript (ES6+)</li>
              </ul>
              <ul>
                <li>Typescript</li>
                <li>MySQL</li>
                <li>MongoDB</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <img
        className="background-image-left"
        alt="Project"
        src={backgroundImageLeft}
      />
    </section>
  );
};

export default About;
