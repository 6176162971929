import "./projectCard.css";
import { Col } from "react-bootstrap";

const ProjectCard = ({
  title,
  description,
  imgUrl,
  githubUrl,
  hasLiveDemo,
  liveUrl,
}) => {
  return (
    <Col sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt={title} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <div className="proj-buttons">
            <span>
              <a href={githubUrl}>Github</a>
            </span>
            {hasLiveDemo && (
              <span className="live">
                <a href={liveUrl}>Demo</a>
              </span>
            )}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ProjectCard;
